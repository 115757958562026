body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

code {
  font-family: Arial, Helvetica, sans-serif;
}

.no-underline {
  text-decoration: none;
}

.inline-button {
  display: inline-block;
  vertical-align: middle;
}

.nav-container {
  display: flex;
  align-items: center;
}

.form-style {
  max-width: 400px;
}

.form-control {
  margin-bottom: 10px;
}

.error-text-color {
  color: red;
  list-style: none;
}

.max {
  max-width: 600px;
}

/* .background-image-login {
  background-image: url(https://images.unsplash.com/photo-1577223645169-1e13f9056226?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80);
  background-repeat: no-repeat;
  background-size: 600px 900px;
  background-position: top left;
} */

.background-image-signup {
  background-image: url(https://images.unsplash.com/photo-1598121876853-82437626c783?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80);
  background-repeat: no-repeat;
  background-size: 600px 900px;
  background-position: top left;
}

.modern-card {
  height: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.card-image {
  height: 0;
  padding-bottom: 55%;
  position: relative;
}

.card-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.id-card {
  height: 0;
  padding-bottom: 55%;
  position: relative;
}

.id-card img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .specific-card {
  height: 0;
  padding-bottom: 55%;
  position: relative;
} */

.stadium-name-id {
  font-weight: bold;
}

/* .container-padding-id {
  padding-left: 0;
  padding-right: 0;
} */

.stadium-card-center {
  justify-content: center;
  text-align: center;
}

.error {
  display: flex;
  color: red;
  justify-content: center;
  text-align: center;
}

.no-stadiums {
  display: flex;
  color: red;
  justify-content: center;
  text-align: center;
  padding-top: 50px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.react-calendar {
  font-family: "Arial", sans-serif;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
}

.react-calendar__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.react-calendar__navigation button {
  background-color: #fff;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.react-calendar__navigation button:hover {
  background-color: #eee;
}

.react-calendar__navigation button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.react-calendar__navigation button svg {
  width: 20px;
  height: 20px;
}

.react-calendar__month-view__days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
}

.react-calendar__month-view__weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  margin-bottom: 10px;
}

.time-link {
  text-decoration: none;
}

.react-calendar__tile {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
  cursor: pointer;
}

.react-calendar__tile:hover {
  background-color: #eee;
}

.react-calendar__tile--active {
  background-color: #007bff;
  color: #fff;
}

.calendar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.calendar-popup {
  background-color: #fff;
  border-radius: 4px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 90%;
  max-width: 600px;
}

.container-padding-id {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.specific-card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.review-container {
  height: 200px; /* Set the desired height of the review container */
  overflow-y: auto; /* Enable vertical scrolling */
  border: 1px solid #ccc; /* Add border for visual separation */
  padding: 10px; /* Add padding for spacing */
}

.review-list {
  list-style-type: none; /* Remove bullet points from the list */
  padding: 0; /* Remove default padding */
}

.input-col {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.input-col .form-control,
.form-select {
  margin-right: 10px;
  width: auto;
  margin-bottom: 20px;
}

.user-tour-name {
  color: blue;
}

.my-account-tours {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
}

.handle-delete {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
